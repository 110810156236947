import React, { ReactElement } from 'react';
import { MutableCustom } from '../../../utils/inputUtils';
import { NOTE_FIELD_LABELS } from '../../../constants';
import { CabIcon } from '@CabComponents/CabIcon';
import { CabTextInput } from '@CabComponents/CabTextInput';
import { Box, FormControl, FormLabel, IconButton, styled } from '@mui/material';
import colors from '../../../colors';
import { CabButton } from '@CabComponents/CabButton';
import { IoAdd, IoCloseCircleOutline } from 'react-icons/io5';


interface NoteFieldProps {
  fieldLabelKey: keyof typeof NOTE_FIELD_LABELS;
  placeholder: string;
  value: string;
  changeFunc: (newValue: string) => void; 
  disabled?: boolean; 
  validateField?: (newValue: string) => void;
  multiline?: boolean;
  minRows?: number;
}

export const FixedNoteField = ({
  fieldLabelKey, placeholder, value, changeFunc, disabled, validateField, multiline, minRows
}: NoteFieldProps): ReactElement => {
  return (
    <FormControl fullWidth>
      <FormLabel>{NOTE_FIELD_LABELS[fieldLabelKey]}</FormLabel>
      <CabTextInput
        value={value}
        onChange={(event): void => {
          changeFunc(String(event.target.value));
          if (validateField) validateField(String(event.target.value));
        }}
        minRows={minRows}
        maxRows={3}
        multiline={multiline}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormControl>
  );
};


interface NoteCustomFieldsProps {
  mutableCustom: MutableCustom;
  changeFunc: (index: number, label?: boolean) => ((newValue: string) => void);
  onDelete: (order: number) => (() => void);
}

export const NoteCustomFields = ({
  mutableCustom, changeFunc, onDelete
}: NoteCustomFieldsProps): ReactElement => (
  <>
    {mutableCustom.map((field, index): ReactElement | void => {
      return (
        <CustomFieldContainer key={'edit' + field.uniqueKey}>
          <Box width='100%' display='flex' flexDirection='column' gap={1} padding={2}>
            <CabTextInput
              value={field.label}
              placeholder="Enter field label..." 
              onChange={(event) => changeFunc(index, true)(String(event.target.value))}
            />
            <CabTextInput
              value={field.value}
              minRows={2}
              maxRows={3}
              multiline
              placeholder="Enter field value..."
              onChange={(event) => changeFunc(index)(String(event.target.value))}
            />
          </Box>
          <Box display='flex' justifyContent={'flex-end'}>
            <IconButton edge='end' aria-label='remove' disableRipple 
              sx={{padding: 0, height: 24, marginRight: .5, marginTop: .5}}
              onClick={onDelete(index)}
            >
              <CabIcon Icon={IoCloseCircleOutline} alt='Remove' />
            </IconButton>
          </Box>
        </CustomFieldContainer>
      );
    }
    )}
  </>
);


interface AddFieldButtonProps {
  onClick: () => void;
}

export const AddFieldButton = ({ onClick }: AddFieldButtonProps): ReactElement => (
  <Box marginTop={1}>
    <CabButton
      buttonType="tertiary"
      color='accent'
      onClick={onClick}
      icon={<CabIcon Icon={IoAdd} alt='Add'/>}
    >
      Add Field
    </CabButton>
  </Box>
);

const CustomFieldContainer = styled(Box, {label: "WidgetContainer"})({
  border: `1px solid ${colors.black300}`,
  backgroundColor: colors.greyBackdrop,
  display: 'flex',
  position: 'relative',
  borderRadius: 6,
  width: '100%',
  marginTop: 8
});